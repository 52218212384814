<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              v-model="filters.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="invoiceNumber"
              v-model="filters.invoiceNumber"
              type="text"
              :label="$t('Número da NF')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="invoice-model"
              v-model="filters.model"
              :placeholder="$t('Todos')"
              type="vue-select"
              :label="$t('Modelo da Nota')"
              :options="invoiceModelOptions()"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="invoice-invoice-status"
              v-model="filters.status"
              :type="'vue-select'"
              :options="invoiceReceivedStatusOptions()"
              :label="$t('Status')"
              @input="onSelectNatureOperation"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="issueDate"
              v-model="filters.issueDate"
              type="date-range-picker"
              :label="$t('Período de emissão')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="document"
              v-model="filters.supplierDocument"
              name="document"
              :label="$t('CPF ou CNPJ')"
              type="text-mask"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :placeholder="$t('CPF ou CNPJ')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="invoice-invoice-natureOperation"
              v-model="filters.cfop"
              :type="'vue-select'"
              :options="getComboCfopNatureOperation"
              :label="$t('Código CFOP')"
              :instruction="$t('Filtra notas que contém algum item com o CFOP informado')"
              @input="onSelectNatureOperation"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="invoice-invoice-reason"
              v-model="filters.reason"
              type="vue-select"
              :label="$t('Motivo')"
              :placeholder="$t('Motivo')"
              :options="invoiceReceivedReasonOptions()"
            />
          </b-col>
        </b-row>
      </div>

      <template #after_buttons>
        <e-button
          class="ml-1"
          :busy="downloading"
          :text="$t('Baixar Relatório')"
          variant="primary"
          icon="download"
          @click="onDownloadReport"
        />
      </template>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="invoices-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('INVOICE.NAME'), 'female')"
        :fields="fields"
        :items="invoices"
        no-local-sorting
        :tbody-tr-class="rowClass"
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="gridButtons(row)"
            @edit="onUpdateInvoice(row)"
            @consult-invoice="onConsultInvoice(row)"
            @download-pdf="onDownloadPdf(row)"
            @download-xml="onDownloadXml(row)"
          />
        </template>

        <template #cell(status)="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <e-instructions
              v-if="item.status === invoiceReceivedStatusEnum.IssuerInvoiceCancelled"
              :title="$t('Informações do cancelamento')"
              :target="`instruction_invoice_received_${item.id}`"
              :instructions="getCancelledInstructionsInfo(item)"
            />

            {{ invoiceReceivedStatusLabel[item.status] }}
          </div>
        </template>

        <template #cell(association)="row">
          <div v-if="row.item.invoiceReason">
            <e-router-link
              v-if="objectRelatedRoute(row.item).routerName"
              :to="{
                name: objectRelatedRoute(row.item).routerName,
                params: objectRelatedRoute(row.item).routerParams,
              }"
              :value="objectRelatedRoute(row.item).value"
            />
            <span v-else>{{ objectRelatedRoute(row.item).value }}</span>
          </div>
          <span v-else>-</span>
        </template>

        <template #cell(store)="row">
          <span :title="$options.filters.storeName(row.item.store)">{{
            row.item.store | storeId
          }}</span><br>
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="3">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <e-color-subtitled
            :colors="[
              { backgroundColor: 'table-success', title: invoiceReceivedStatusLabel.Processed },
              { backgroundColor: 'table-danger', title: invoiceReceivedStatusLabel.IssuerInvoiceCancelled },
            ]"
          />
        </b-col>
        <b-col cols="3">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="invoices-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <!-- <fab
      v-if="$can('Create', 'Invoice')"
      :main-tooltip="$t('Adicionar Nota Fiscal')"
      @click="onCreateInvoice"
    /> -->
    <fab
      v-if="$can('Create', 'Invoice')"
      :main-tooltip="$t('Adicionar Nota Fiscal')"
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      @create-with-import="onCreateInvoiceFromCoupon"
      @create-manual="onCreateInvoice"
    />

    <invoice-details-modal ref="invoiceDetailsModal" />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStoreCombo,
  EFilters,
  EGridActions,
  EButton,
  ERouterLink,
  EColorSubtitled,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { downloader, invoiceDomains, statusTypes } from '@/mixins'
import { mapActions, mapState, mapGetters } from 'vuex'
import EInstructions from '@/views/components/EInstructions.vue'
import InvoiceDetailsModal from './components/InvoiceDetailsModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPageSizeSelector,
    EPaginationSummary,
    fab,
    EFilters,
    EGridActions,
    EStoreCombo,
    InvoiceDetailsModal,
    EButton,
    ERouterLink,
    EColorSubtitled,
    EInstructions,
  },

  mixins: [statusTypes, invoiceDomains, downloader],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
      downloading: false,
    }
  },

  computed: {
    ...mapState('pages/invoice/invoiceReceived', ['invoices', 'paging', 'sorting', 'filters']),
    ...mapGetters('pages/invoice/taxClassifications', [
      'getComboCfopNatureOperation',
      'getCfopNatureOperation',
    ]),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Número'),
          key: 'invoiceNumber',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          sortable: true,
          formatter: (val, index, item) =>
            `${this.$options.filters.segments2(val)}${item.model ? ` (${item.model})` : ''}`,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
        },
        {
          label: this.$t('Fornecedor'),
          key: 'supplier',
          tdClass: 'text-left',
          formatter: (value, index, item) =>
            `${this.$options.filters.cpfCnpj(item.supplierDocument)} - ${value}`,
        },
        {
          label: this.$t('Valor'),
          key: 'totalValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
        {
          label: this.$t('Data Emissão'),
          key: 'issueDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.date(value),
          sortable: true,
        },
        {
          label: this.$t('Data Vencimento'),
          key: 'dueDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.date(value) || '-',
          sortable: true,
        },
        {
          label: this.$t('Motivo'),
          key: 'invoiceReason',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          formatter: value => this.invoiceReceivedReasonLabel[value] || '-',
          sortable: true,
        },
        {
          label: this.$t('Associação'),
          key: 'association',
          tdClass: 'text-center',
          // thStyle: { width: '120px' },
        },
      ]
    },

    gridButtons() {
      return row => {
        const { SUCCESS_FINISHED, CANCELED, INVALIDATED } = this.statusInvoiceEnum
        const canConsultInvoice =
          row.item.status === SUCCESS_FINISHED ||
          row.item.status === CANCELED ||
          row.item.status === INVALIDATED
        // TODO permissão
        let btns = []
        if (canConsultInvoice) {
          btns = [
            {
              icon: 'search',
              variant: 'primary',
              title: this.$t('Consultar Status da Nota'),
              event: 'consult-invoice',
              // hide: row.item.status === INVALIDATED,
            },
            {
              icon: 'eye-fill',
              variant: 'primary',
              title: this.$t('Visualizar Nota'),
              event: 'edit',
              hide: true,
            },
            {
              icon: 'printer-fill',
              variant: 'primary',
              title: this.$t('Download do PDF'),
              event: 'download-pdf',
              hide: row.item.status === INVALIDATED,
            },
            {
              icon: 'download',
              variant: 'primary',
              title: this.$t('Download do XML'),
              event: 'download-xml',
              hide: row.item.status === INVALIDATED,
            },
          ]
        } else {
          btns = [
            {
              icon: 'search',
              variant: 'primary',
              title: this.$t('Consultar Status da Nota'),
              event: 'consult-invoice',
              // hide: row.item.status === INVALIDATED,
            },
          ]
        }

        return btns.filter(btn => !btn.hide)
      }
    },

    actions() {
      return [
        {
          name: 'create-with-import',
          icon: 'note_add',
          color: 'green',
          tooltip: this.$t('Importando Cupom Fiscal'),
        },
        {
          name: 'create-manual',
          icon: 'add',
          color: 'green',
          tooltip: this.$t('Manual'),
        },
      ]
    },

    objectRelatedRoute() {
      return item => {
        switch (item.invoiceReason) {
          case 'SalableProducts':
          case 'Lending':
            return {
              value: `${this.$t('Pedido de compra #')} ${item.purchaseOrderId} (${item.acknowledgeType === 'Manual'
                ? `${this.$t('Manual')} - ${item.validatedBy?.name}`
                : this.$t('Automático')
                })`,
              routerName: 'purchase-order-details',
              routerParams: { id: item.purchaseOrderId },
            }
          case 'Inputs':
          case 'FixedAssets':
          case 'OngoingServices':
          case 'Services':
            // eslint-disable-next-line no-case-declarations
            const [accountsPayable] = item.accountsPayable
            return {
              value: `${this.$t('Despesa #')}${accountsPayable?.id} (${item.validatedBy?.name})`,
              routerName: null,
              // routerParams: { id: item.purchaseOrderId },
            }
          default:
            return {}
        }
      }
    },
  },

  mounted() {
    this.getData()
    this.fetchTaxClassificationCombos()
  },

  methods: {
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    ...mapActions('pages/invoice/invoiceReceived', [
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'fetchInvoices',
      'resetFilters',
      'downloadReport',
    ]),
    ...mapActions('pages/invoice/invoice/invoiceMaintain', {
      stCleanInvoiceMaintain: 'cleanState',
      stInvoiceManage: 'invoiceManage',
      stInvoiceDownload: 'invoiceDownload',
      cleanSaleInvoices: 'cleanSaleInvoices',
    }),

    onSelectNatureOperation(val) {
      if (!val) {
        this.$emit('clean-nature-operation')
        return
      }
      if (val.includes('-')) {
        for (let i = 0; i < this.getCfopNatureOperation.length; i += 1) {
          if (this.getCfopNatureOperation[i].code === Number(val.split('-')[0].trim())) {
            this.$emit('set-nature-operation', this.getCfopNatureOperation[i])
          }
        }
      }
    },

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchInvoices()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateInvoice() {
      this.stCleanInvoiceMaintain()
      this.$router.push({ name: 'invoice-create' })
    },

    onCreateInvoiceFromCoupon() {
      this.stCleanInvoiceMaintain()
      this.cleanSaleInvoices()
      this.$router.push({ name: 'invoice-create-from-coupon' })
    },

    async onUpdateInvoice(row) {
      const { id } = row.item
      this.$router.push({ name: 'invoice-maintain', params: { id } })
    },
    async onConsultInvoice(row) {
      await this.$refs.invoiceDetailsModal.show(row.item.id)
    },
    async onDownloadPdf(row) {
      try {
        this.fetching = true

        const ret = await this.stInvoiceDownload({
          pathUrl: row.item.nfeDetail?.danfeNFePath,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          const linkSource = `data:application/pdf;base64,${ret.responsePayload}`
          const downloadLink = document.createElement('a')
          const fileName = `${row.item.nfeDetail?.accessKey}.pdf`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao imprimir a NFe!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    async onDownloadXml(row) {
      try {
        this.fetching = true

        const ret = await this.stInvoiceDownload({
          pathUrl: row.item.nfeDetail?.xmlFinishedSuccessPath,
        })

        if (ret.responsePayload && ret.responsePayload !== '') {
          const linkSource = `data:application/pdf;base64,${ret.responsePayload}`
          const downloadLink = document.createElement('a')
          const fileName = `${row.item.nfeDetail?.accessKey}.xml`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.showSuccess({ message: this.$t('Sucesso.') })
        } else {
          this.showError('Ocorreu um erro ao imprimir a NFe!')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    async onDownloadReport() {
      try {
        this.downloading = true
        const blobData = await this.downloadReport()

        if (blobData) {
          this.forceFileDownloadXlsx(blobData, this.$t('Relatorio_Nota_Fiscal'))
          this.showSuccess({ message: this.$t('Sucesso') })
        } else {
          this.showError('Ocorreu um erro ao realizar o download do relatório de Nota fiscal')
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.downloading = false
      }
    },

    getCancelledInstructionsInfo(invoiceReceived) {
      const cancelledEvent = invoiceReceived.invoiceIssuerCancelledEvent

      const cancelDate = this.$options.filters.datetime(cancelledEvent.eventDate)
      return [
        { text: `Cancelado em: ${cancelDate}` },
        { text: `Justificativa: ${cancelledEvent.justification}` },
        { text: `Protocolo: ${cancelledEvent.protocol}` },
      ]
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      if (item.status === this.invoiceReceivedStatusEnum.IssuerInvoiceCancelled) return 'table-danger'
      if (item.status === this.invoiceReceivedStatusEnum.Processed) return 'table-success'

      return ''
    },
  },
}
</script>

<style></style>
