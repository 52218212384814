var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1",
                    attrs: {
                      busy: _vm.downloading,
                      text: _vm.$t("Baixar Relatório"),
                      variant: "primary",
                      icon: "download",
                    },
                    on: { click: _vm.onDownloadReport },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          required: false,
                          placeholder: _vm.$t("Todas"),
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoiceNumber",
                          type: "text",
                          label: _vm.$t("Número da NF"),
                        },
                        model: {
                          value: _vm.filters.invoiceNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "invoiceNumber", $$v)
                          },
                          expression: "filters.invoiceNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-model",
                          placeholder: _vm.$t("Todos"),
                          type: "vue-select",
                          label: _vm.$t("Modelo da Nota"),
                          options: _vm.invoiceModelOptions(),
                        },
                        model: {
                          value: _vm.filters.model,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "model", $$v)
                          },
                          expression: "filters.model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-invoice-status",
                          type: "vue-select",
                          options: _vm.invoiceReceivedStatusOptions(),
                          label: _vm.$t("Status"),
                        },
                        on: { input: _vm.onSelectNatureOperation },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "issueDate",
                          type: "date-range-picker",
                          label: _vm.$t("Período de emissão"),
                        },
                        model: {
                          value: _vm.filters.issueDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "issueDate", $$v)
                          },
                          expression: "filters.issueDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "document",
                          name: "document",
                          label: _vm.$t("CPF ou CNPJ"),
                          type: "text-mask",
                          mask: ["###.###.###-##", "##.###.###/####-##"],
                          placeholder: _vm.$t("CPF ou CNPJ"),
                        },
                        model: {
                          value: _vm.filters.supplierDocument,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "supplierDocument", $$v)
                          },
                          expression: "filters.supplierDocument",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-invoice-natureOperation",
                          type: "vue-select",
                          options: _vm.getComboCfopNatureOperation,
                          label: _vm.$t("Código CFOP"),
                          instruction: _vm.$t(
                            "Filtra notas que contém algum item com o CFOP informado"
                          ),
                        },
                        on: { input: _vm.onSelectNatureOperation },
                        model: {
                          value: _vm.filters.cfop,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "cfop", $$v)
                          },
                          expression: "filters.cfop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "invoice-invoice-reason",
                          type: "vue-select",
                          label: _vm.$t("Motivo"),
                          placeholder: _vm.$t("Motivo"),
                          options: _vm.invoiceReceivedReasonOptions(),
                        },
                        model: {
                          value: _vm.filters.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "reason", $$v)
                          },
                          expression: "filters.reason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "invoices-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("INVOICE.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.invoices,
              "no-local-sorting": "",
              "tbody-tr-class": _vm.rowClass,
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: _vm.gridButtons(row),
                      },
                      on: {
                        edit: function ($event) {
                          return _vm.onUpdateInvoice(row)
                        },
                        "consult-invoice": function ($event) {
                          return _vm.onConsultInvoice(row)
                        },
                        "download-pdf": function ($event) {
                          return _vm.onDownloadPdf(row)
                        },
                        "download-xml": function ($event) {
                          return _vm.onDownloadXml(row)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center",
                      },
                      [
                        item.status ===
                        _vm.invoiceReceivedStatusEnum.IssuerInvoiceCancelled
                          ? _c("e-instructions", {
                              attrs: {
                                title: _vm.$t("Informações do cancelamento"),
                                target:
                                  "instruction_invoice_received_" + item.id,
                                instructions:
                                  _vm.getCancelledInstructionsInfo(item),
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.invoiceReceivedStatusLabel[item.status]
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(association)",
                fn: function (row) {
                  return [
                    row.item.invoiceReason
                      ? _c(
                          "div",
                          [
                            _vm.objectRelatedRoute(row.item).routerName
                              ? _c("e-router-link", {
                                  attrs: {
                                    to: {
                                      name: _vm.objectRelatedRoute(row.item)
                                        .routerName,
                                      params: _vm.objectRelatedRoute(row.item)
                                        .routerParams,
                                    },
                                    value: _vm.objectRelatedRoute(row.item)
                                      .value,
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.objectRelatedRoute(row.item).value
                                    )
                                  ),
                                ]),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "cell(store)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        attrs: {
                          title: _vm.$options.filters.storeName(row.item.store),
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("storeId")(row.item.store)))]
                    ),
                    _c("br"),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-color-subtitled", {
                    attrs: {
                      colors: [
                        {
                          backgroundColor: "table-success",
                          title: _vm.invoiceReceivedStatusLabel.Processed,
                        },
                        {
                          backgroundColor: "table-danger",
                          title:
                            _vm.invoiceReceivedStatusLabel
                              .IssuerInvoiceCancelled,
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "invoices-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Invoice")
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Adicionar Nota Fiscal"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
            },
            on: {
              "create-with-import": _vm.onCreateInvoiceFromCoupon,
              "create-manual": _vm.onCreateInvoice,
            },
          })
        : _vm._e(),
      _c("invoice-details-modal", { ref: "invoiceDetailsModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }